var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.disabled,
                                expression: "disabled",
                              },
                            ],
                            attrs: { label: "출력", icon: "print" },
                            on: { btnClicked: _vm.printTbm },
                          }),
                          _vm.editable && _vm.popupParam.tbmId
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    label: "QR 코드",
                                    icon: "qr_code_scanner",
                                    color: "black",
                                    size: "md",
                                  },
                                },
                                [
                                  _c(
                                    "q-popup-proxy",
                                    [
                                      _c(
                                        "c-card",
                                        {
                                          staticClass: "cardClassDetailForm",
                                          attrs: { title: "QR코드" },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "card-button" },
                                            [
                                              _c("c-btn", {
                                                attrs: {
                                                  label: "",
                                                  icon: "print",
                                                },
                                                on: { btnClicked: _vm.print },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "template",
                                            { slot: "card-detail" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "wrap",
                                                          attrs: {
                                                            action: "#",
                                                            id: "printJS-form",
                                                          },
                                                        },
                                                        [
                                                          _c("vue-qrcode", {
                                                            staticClass:
                                                              "canvas",
                                                            attrs: {
                                                              value:
                                                                _vm.serverName +
                                                                "/sai/tbm/tbm?tbmId=" +
                                                                _vm.popupParam
                                                                  .tbmId,
                                                              options: {
                                                                width: 180,
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "facilityStyle",
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "abc",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        "-tbm명 : "
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.data
                                                                        .tbmName
                                                                    ) + " "
                                                                  ),
                                                                  _c("br"),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        "-작업일 : "
                                                                      ) + " "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.data
                                                                        .tbmWorkDate
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.popupParam.tbmId,
                                expression: "editable && !popupParam.tbmId",
                              },
                            ],
                            attrs: {
                              label: "타 TBM 불러오기",
                              icon: "save_alt",
                            },
                            on: { btnClicked: _vm.copyTbm },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.tbmId &&
                                  !_vm.disabled,
                                expression:
                                  "editable && popupParam.tbmId && !disabled",
                              },
                            ],
                            attrs: {
                              label: "삭제",
                              editable: _vm.editable,
                              icon: "delete_forever",
                            },
                            on: { btnClicked: _vm.remove },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.tbmId &&
                                  !_vm.disabled,
                                expression:
                                  "editable && popupParam.tbmId && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.completeUrl,
                              isSubmit: _vm.isComplete,
                              param: _vm.data,
                              mappingType: "PUT",
                              label: "완료",
                              icon: "check",
                            },
                            on: {
                              beforeAction: _vm.completeTbm,
                              btnCallback: _vm.completeCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.data,
                              mappingType: _vm.saveType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveTbm,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            changeItem: _vm.changeItem,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.data.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmNo", $$v)
                            },
                            expression: "data.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            afterIcon:
                              _vm.editable && !_vm.disabled
                                ? [
                                    {
                                      name: "visibility",
                                      click: true,
                                      callbackName: "detailWorkPermit",
                                    },
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchWorkPermit",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeWorkPermit",
                                    },
                                  ]
                                : [
                                    {
                                      name: "visibility",
                                      click: true,
                                      callbackName: "detailWorkPermit",
                                    },
                                  ],
                            editable: _vm.editable,
                            readonly: true,
                            label: "작업허가번호",
                            name: "permitNo",
                          },
                          on: {
                            detailWorkPermit: _vm.detailWorkPermit,
                            searchWorkPermit: _vm.searchWorkPermit,
                            removeWorkPermit: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.data.permitNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "permitNo", $$v)
                            },
                            expression: "data.permitNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.data.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmName", $$v)
                            },
                            expression: "data.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-vendor-multi", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            plantCd: _vm.data.plantCd,
                            changeItem: _vm.changeItem,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.data.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorCd", $$v)
                            },
                            expression: "data.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            changeItem: _vm.changeItem,
                            plantCd: _vm.data.plantCd,
                            label: "공종",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.data.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processCd", $$v)
                            },
                            expression: "data.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            editable: _vm.editable,
                            label: "작업",
                            name: "sopName",
                          },
                          model: {
                            value: _vm.data.sopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sopName", $$v)
                            },
                            expression: "data.sopName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            label: "작업일",
                            type: "date",
                            name: "tbmWorkDate",
                          },
                          model: {
                            value: _vm.data.tbmWorkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmWorkDate", $$v)
                            },
                            expression: "data.tbmWorkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            changeItem: _vm.changeItem,
                            disabled: _vm.disabled,
                            plantCd: _vm.data.plantCd,
                            isVendorOrg: true,
                            label: "작업책임자(주관)",
                            name: "tbmWorkResponsibleId",
                          },
                          model: {
                            value: _vm.data.tbmWorkResponsibleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmWorkResponsibleId", $$v)
                            },
                            expression: "data.tbmWorkResponsibleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "TBM_PRE_INSPECTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사전점검",
                            name: "preInspections",
                          },
                          model: {
                            value: _vm.data.preInspections,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "preInspections", $$v)
                            },
                            expression: "data.preInspections",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            rows: 2,
                            name: "tbmWorkContents",
                          },
                          model: {
                            value: _vm.data.tbmWorkContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmWorkContents", $$v)
                            },
                            expression: "data.tbmWorkContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "특이사항",
                            rows: 2,
                            name: "significant",
                          },
                          model: {
                            value: _vm.data.significant,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "significant", $$v)
                            },
                            expression: "data.significant",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c("c-tab", {
                staticClass: "q-mt-sm preStartup-tab-no-padding",
                attrs: {
                  type: "tabcard",
                  tabItems: _vm.tabItems,
                  inlineLabel: true,
                  emptyShow: true,
                  align: "left",
                },
                on: {
                  "update:tabItems": function ($event) {
                    _vm.tabItems = $event
                  },
                  "update:tab-items": function ($event) {
                    _vm.tabItems = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (tab) {
                      return [
                        _c(tab.component, {
                          tag: "component",
                          attrs: {
                            popupParam: _vm.popupParam,
                            data: _vm.data,
                            contentHeight: _vm.contentHeight,
                          },
                          on: {
                            "update:popupParam": function ($event) {
                              _vm.popupParam = $event
                            },
                            "update:popup-param": function ($event) {
                              _vm.popupParam = $event
                            },
                            "update:data": function ($event) {
                              _vm.data = $event
                            },
                            getDetail: _vm.getDetail,
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }