<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-show="disabled"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <q-btn v-if="editable && popupParam.tbmId" class="custom-btn" label="QR 코드" icon="qr_code_scanner" color="black" size="md">
                  <q-popup-proxy>
                    <c-card title="QR코드" class="cardClassDetailForm">
                      <template slot="card-button">
                        <c-btn label="" icon="print" @btnClicked="print" />
                      </template>
                      <template slot="card-detail">
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="wrap" action="#" id="printJS-form">
                              <vue-qrcode
                                :value="serverName+'/sai/tbm/tbm?tbmId='
                                + popupParam.tbmId" 
                                :options="{ width: 180 }"
                                class="canvas"
                              >
                              </vue-qrcode>
                              <span class="facilityStyle">
                                <p class="abc" >
                                  <b>{{'-tbm명 : '}}</b>{{data.tbmName}}
                                  <br>
                                  <b>{{'-작업일 : '}} </b>{{data.tbmWorkDate}}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
                <!-- v-show="disabled" -->
                <c-btn
                  v-show="editable && !popupParam.tbmId"
                  label="타 TBM 불러오기"
                  icon="save_alt"
                  @btnClicked="copyTbm"
                />
                <c-btn
                  v-show="editable && popupParam.tbmId && !disabled"
                  label="삭제"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
                <c-btn
                  v-show="editable && popupParam.tbmId && !disabled"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="data"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="completeTbm"
                  @btnCallback="completeCallback"
                />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveTbm"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="isWorkPermit || disabled"
                  :changeItem="changeItem"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="TBM번호"
                  name="tbmNo"
                  v-model="data.tbmNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :afterIcon="
                    editable && !disabled
                      ? [
                          {
                            name: 'visibility',
                            click: true,
                            callbackName: 'detailWorkPermit',
                          },
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchWorkPermit',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeWorkPermit',
                          },
                        ]
                      : 
                      [
                        {
                          name: 'visibility',
                          click: true,
                          callbackName: 'detailWorkPermit',
                        },
                      ]
                  "
                  :editable="editable"
                  :readonly="true"
                  label="작업허가번호"
                  name="permitNo"
                  v-model="data.permitNo"
                  @detailWorkPermit="detailWorkPermit"
                  @searchWorkPermit="searchWorkPermit"
                  @removeWorkPermit="removeWorkPermit"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM명"
                  name="tbmName"
                  v-model="data.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor-multi
                  required
                  :editable="editable"
                  :disabled="isWorkPermit || disabled"
                  :plantCd="data.plantCd"
                  :changeItem="changeItem"
                  label="업체"
                  name="vendorCd"
                  v-model="data.vendorCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-process
                  :editable="editable"
                  :disabled="isWorkPermit || disabled"
                  :changeItem="changeItem"
                  :plantCd="data.plantCd"
                  label="공종"
                  name="processCd"
                  v-model="data.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :disabled="isWorkPermit || disabled"
                  :editable="editable"
                  label="작업"
                  name="sopName"
                  v-model="data.sopName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="isWorkPermit || disabled"
                  label="작업일"
                  type="date"
                  name="tbmWorkDate"
                  v-model="data.tbmWorkDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 작업허가서의 업체감독자, 공사현장 선택시 안전관리책임자 -->
                <c-field
                  :editable="editable"
                  :changeItem="changeItem"
                  :disabled="disabled"
                  :plantCd="data.plantCd"
                  :isVendorOrg="true"
                  label="작업책임자(주관)"
                  name="tbmWorkResponsibleId"
                  v-model="data.tbmWorkResponsibleId"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-checkbox
                  :disabled="disabled"
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="TBM_PRE_INSPECTION_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="사전점검"
                  name="preInspections"
                  v-model="data.preInspections"
                >
                </c-checkbox>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  :rows="2"
                  name="tbmWorkContents"
                  v-model="data.tbmWorkContents"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="특이사항"
                  :rows="2"
                  name="significant"
                  v-model="data.significant"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            class="q-mt-sm preStartup-tab-no-padding"
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            :emptyShow="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :popupParam.sync="popupParam"
                :data.sync="data"
                :contentHeight="contentHeight"
                @getDetail="getDetail"
              />
            </template>
          </c-tab>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import printJs from 'print-js'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "tbm-info",
  components: {
    VueQrcode
  },
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      tab: 'tbmRiskHazard',
      tabItems: [
        { name: 'tbmRiskHazard', icon: 'list', label: '위험요인', component: () => import(`${'./tbmRiskHazard.vue'}`) },
        { name: 'tbmWorkerHealthStatus', icon: 'health_and_safety', label: '근로자 건강상태', component: () => import(`${'./tbmWorkerHealthStatus.vue'}`) },
      ],
      data: {
        tbmId: "",
        tbmNo: "",
        plantCd: "",
        permitNo: "",
        sopName: "",
        mapName: '',
        tbmWorkResponsibleId: "",
        tbmWorkResponsibleName: "",
        tbmName: "",
        tbmWorkDate: "",
        tbmWorkArea: "",
        tbmWorkContents: "",
        significant: "",
        tbmCompleteFlag: 'N',
        processCd: "",
        vendorCd: "",
        sopWorkPermitId: "",
        preInspections: "",
        tbmWorkPermitFlag: 'N',
        companyCd: '',

        tbmAttendeeModelList: [],
        tbmRiskHazardModelList: [],

        deleteTbmAttendeeModelList: [],
        deleteTbmRiskHazardModelList: [],
      },
      grid: {
        columns: [
          {
            name: "tbmJobStep",
            field: "tbmJobStep",
            label: "작업단계",
            align: "left",
            style: "width:30%",
            type: "text",
            sortable: false,
          },
          {
            name: "tbmRiskHazard",
            field: "tbmRiskHazard",
            label: "위험요인",
            align: "left",
            style: "width:30%",
            type: "text",
            sortable: false,
          },
          {
            name: "tbmImprovementMeasures",
            field: "tbmImprovementMeasures",
            label: "안전대책",
            type: "text",
            style: "width:40%",
            align: "left",
            sortable: false,
          },
          // {
          //   name: "type",
          //   field: "type",
          //   label: "구분",
          //   style: "width:10%",
          //   align: "center",
          //   sortable: false,
          // },
        ],
        height: "500px",
        data: [],
      },
      grid2: {
        columns: [],
        height: '500px',
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      getUrl: "",
      saveUrl: "transactionConfig.sai.tbm.insert.url",
      completeUrl: "transactionConfig.sai.tbm.complete.url",
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getRespondUrl: '',
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      redirectUrl: '',
      serverName: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      scenarioViewGroup: 'L',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isWorkPermit() {
      return (
        Boolean(this.data.sopWorkPermitId) &&
        this.editable
      );
    },
    disabled() {
      return this.data.tbmCompleteFlag === "Y";
    },
    isPlant() {
      return Boolean(!this.data.plantCd)
    },
    isProcess() {
      return Boolean(!this.data.plantCd) || Boolean(!this.data.processCd)
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
    gridColumns() {
      let cols = [];
      cols = [
        {
          name: "tbmJobStep",
          field: "tbmJobStep",
          label: "작업단계",
          align: "left",
          style: "width:30%",
          type: "text",
          sortable: false,
        },
        {
          name: "tbmRiskHazard",
          field: "tbmRiskHazard",
          label: "위험요인",
          align: "left",
          style: "width:30%",
          type: "text",
          sortable: false,
        },
        {
          name: "tbmImprovementMeasures",
          field: "tbmImprovementMeasures",
          label: "안전대책",
          type: "text",
          style: "width:40%",
          align: "left",
          sortable: false,
        },
      ]
      return cols
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 270);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  // * 작업허가서 있을때
  // - 프로젝트, 공종, 업체, 작업, 작업책임자(업체감독자), 작업장소, 작업일, 작업내용 전부 disabled
  // - 참석자 : 작업허가서의 작업자
  // * 작업허가서 없을때
  // - 공사현장과 관련된 공종 선택
  // - 작업장소 : 공사현장 > 작업장소
  // - 작업책임자 : 하도급 > 안전책임자
  // - 작업 : 공종, 업체에 맞는 작업 선택
  // - 업체 : 프로젝트와 공종에 맞는 하도급 선택
  // - 위험요인 목록  작업과 관련된 작업단계(직접추가 제외)
  // - 참석자 : 직영일때 사용자 추가(이름, 직책/직무 수정X), 업체일때 행추가(이름, 직책/직무 수정O) 
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.getUrl = selectConfig.sai.tbm.get.url;
      this.getRespondUrl = selectConfig.sai.tbm.respond.url;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.printUrl = selectConfig.sai.tbm.print.url;
      this.redirectUrl = selectConfig.sai.tbm.qrUrl.url;
      this.getDetail();
      this.setHeader();
      this.serverUrl();
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '15px',
        targetStyles: ['*'],
      })
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
        },);
      } else {
        if (this.$store.getters.user.innerFlag === 'N') {
          this.data.vendorCd = this.$store.getters.user.deptCd;
        }
        this.data.tbmWorkDate = this.$comm.getToday();
      }
    },
    
    setHeader() {
      this.$comm.getComboItems('TBM_HEALTH_STATUS_CD').then(_item => {
        this.grid2.columns = [
          {
            name: "deptName",
            field: "deptName",
            label: "업체",
            align: "center",
            sortable: false,
          },
          {
            name: "tbmAttendeeUserName",
            field: "tbmAttendeeUserName",
            label: "참석자",
            align: "center",
            style: 'width:130px',
            sortable: false,
          },
          // {
          //   name: "tbmAttendeeJobName",
          //   field: "tbmAttendeeJobName",
          //   label: "역할",
          //   style: 'width:100px',
          //   type: 'custom',
          //   align: "center",
          //   sortable: false,
          // },
          {
            name: "jobName",
            field: "jobName",
            label: "직위",
            style: 'width:130px',
            align: "center",
            sortable: false,
          },
          {
            name: 'healthStatusCd',
            field: 'healthStatusCd',
            label: '건강상태',
            type: 'select',
            setHeader: true,
            style: 'width:150px',
            align: 'center',
            comboItems: _item,
            sortable: false,
          },
          {
            name: 'mentalFatigueFlag',
            field: 'mentalFatigueFlag',
            label: '정신적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            value: 'N',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'mentalPhychologicalFlag',
            field: 'mentalPhychologicalFlag',
            label: '심리적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            value: 'N',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'mentalPhysicalFlag',
            field: 'mentalPhysicalFlag',
            label: '육체적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            value: 'N',
            true: 'Y',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'physicalDefectFlag',
            field: 'physicalDefectFlag',
            label: '신체적<br>결함',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            value: 'N',
            true: 'Y',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'eduAttendFlag',
            field: 'eduAttendFlag',
            label: '교육참석<br>여부',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명<br>',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          // {
          //   label: '항목',
          //   align: 'center',
          //   sortable: false,
          //   child: [
          //   ]
          // },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   type: 'text',
          //   style: 'width:800px',
          //   align: 'left',
          //   sortable: false,
          // },
        ]
      });
    },
    saveTbm() {
      if (this.popupParam.tbmId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
        this.data.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.forEach(this.data.tbmRiskHazardModelList, item => {
                item.chgUserId = this.$store.getters.user.userId
              })
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      this.popupParam.tbmId = _result.data
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
    completeTbm() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
            window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "TBM을 완료하시겠습니까?",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    completeCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
    addRisk() {
      this.data.tbmRiskHazardModelList.splice(0, 0, {
        tbmId: this.popupParam.tbmId,
        tbmRiskId: uid(),
        tbmJobStep: "",
        tbmRiskHazard: "",
        tbmImprovementMeasures: "",
        tbmSopFlag: "N",
        tbmJsaFlag: "N",
        tbmDirectFlag: "Y",
        tbmPermitFlag: "N",
        regUserId: this.$store.getters.user.userId,
        remarks: "",
        editFlag: "C",
        type: '직접입력'
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.data.deleteTbmRiskHazardModelList) {
            this.data.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteTbmRiskHazardModelList.push(item);
          }
          this.data.tbmRiskHazardModelList = this.$_.reject(
            this.data.tbmRiskHazardModelList,
            item
          );
        });
      }
    },
    copyTbm() {
      this.popupOptions.title = "타 TBM 검색"; // SOP 검색
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./tbmPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTbmPopup;
    },
    closeCopyTbmPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.data.tbmRiskHazardModelList = [];
      if (data && data.length > 0) {
        if (data[0].tbmId) {
          this.$http.url = this.$format(this.getUrl, data[0].tbmId);
          this.$http.type = "GET";
          this.$http.request((_result) => {
            this.data.plantCd = _result.data.plantCd;
            this.data.permitNo = _result.data.permitNo;
            this.data.sopName = _result.data.sopName;
            this.data.tbmName = _result.data.tbmName;
            this.data.tbmWorkResponsibleId =
              _result.data.tbmWorkResponsibleId;
            this.data.tbmWorkResponsibleName =
              _result.data.tbmWorkResponsibleName;
            this.data.tbmWorkDate = _result.data.tbmWorkDate;
            this.data.tbmWorkArea = _result.data.tbmWorkArea;
            this.data.tbmWorkContents = _result.data.tbmWorkContents;
            this.data.tbmCompleteFlag = "N";
            this.data.processCd = _result.data.processCd;
            this.data.vendorCd = _result.data.vendorCd;
            this.data.sopWorkPermitId = _result.data.sopWorkPermitId;
            this.data.preInspections = _result.data.preInspections;
            this.data.tbmAttendeeModelList =
              _result.data.tbmAttendeeModelList;
            this.data.tbmRiskHazardModelList =
              _result.data.tbmRiskHazardModelList;
            this.data.tbmHazardEquipModelList =
              _result.data.tbmHazardEquipModelList;
          });
        }
      }
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.tbmId, 'N');
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.data.tbmNo + ".docx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    detailWorkPermit() {
      if (this.data.sopWorkPermitId) {
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: this.data.sopWorkPermitId,
          permitTypeCd: this.data.permitTypeCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    // 작업허가서 선택
    searchWorkPermit() {
      if (!this.data.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사현장을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      }
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.data.plantCd, 
        vendorCd: this.data.vendorCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
      this.$http.url = this.$format(this.getWorkPermitUrl, data[0].sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.sopWorkPermitId = _result.data.sopWorkPermitId
        this.data.permitNo = _result.data.permitNo // 허가번호
        this.data.tbmWorkDate = _result.data.permitDate // 작업일
        this.data.processCd = _result.data.processCd // 공종
        this.data.vendorCd = _result.data.vendorCd // 업체
        this.data.tbmWorkResponsibleId = _result.data.maintenanceDeptCheckUserId // 책임자
        this.data.tbmWorkResponsibleName = _result.data.maintenanceDeptCheckUserName // 책임자
        this.data.sopName = _result.data.sopName // 작업
        this.data.tbmName = _result.data.workSummary // TBM명
        this.data.tbmWorkContents = '' // 작업내용
        this.data.tbmWorkPermitFlag = "Y" // 작업허가서 유 
        
        // this.$_.forEach(this.data.tbmRiskHazardModelList, (item) => {
        //   if (!this.data.deleteTbmRiskHazardModelList) {
        //     this.data.deleteTbmRiskHazardModelList = [];
        //   }
        //   if (
        //     this.$_.findIndex(this.data.deleteTbmRiskHazardModelList, {
        //       tbmRiskId: item.tbmRiskId,
        //     }) === -1 &&
        //     item.editFlag !== "C"
        //   ) {
        //     this.data.deleteTbmRiskHazardModelList.push(item);
        //   }
        //   this.data.tbmRiskHazardModelList = this.$_.reject(
        //     this.data.tbmRiskHazardModelList,
        //     item
        //   );
        // });
        // this.data.tbmRiskHazardModelList = [];
        if (_result.data.workers && _result.data.workers.length > 0) {
          this.$_.forEach(_result.data.workers, _item => {
            let index = this.$_.findIndex(this.data.tbmAttendeeModelList, {
              tbmAttendeeUserName: _item.workerName, 
              tbmAttendeeJobName: _item.roleName, 
            });
            if (index === -1) {
              this.data.tbmAttendeeModelList.push({
                tbmId: this.popupParam.tbmId,
                tbmAttendeeId: uid(),
                tbmAttendeeUserId: _item.userId,
                tbmAttendeeUserName: _item.workerName,
                tbmAttendeeJobName: _item.roleName,
                deptName: _item.deptName,
                healthStatusCd: "THS0000001",
                electronSignature: '',
                mentalFatigueFlag: "N",
                mentalPhychologicalFlag: "N",
                mentalPhysicalFlag: "N",
                eduAttendFlag: 'N',
                physicalDefectFlag: "N",
                tbmPermitFlag: "Y",
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            }
          })
        } 
        if (_result.data.assessments && _result.data.assessments.length > 0) {
          this.$_.forEach(_result.data.assessments, _item => {
            let index = this.$_.findIndex(this.data.tbmRiskHazardModelList, {
              tbmJobStep: _item.jobStepName, 
              tbmRiskHazard: _item.riskHazardName, 
            });
            if (index === -1) {
              this.data.tbmRiskHazardModelList.push({
                tbmId: this.popupParam.tbmId,
                tbmRiskId: uid(),
                tbmJobStep: _item.jobStepName,
                tbmRiskHazard: _item.riskHazardName,
                tbmImprovementMeasures: _item.safetyActionMeasures,
                remark: _item.jobStepName + '/' + _item.riskHazardName + '/' + _item.safetyActionMeasures,
                tbmSopFlag: "N",
                tbmJsaFlag: "N",
                tbmDirectFlag: "N",
                tbmPermitFlag: "Y",
                regUserId: this.$store.getters.user.userId,
                remarks: "",
                editFlag: "C",
                type: '작업허가서'
              })
            }
          })
        } 
      },);
      }
    },
    removeWorkPermit() {
      window.getApp.$emit("CONFIRM", {
        title: "확인",
        message:
            "해당 작업허가서에 지정된 기본 정보들과 작업자가 삭제됩니다.\n\r진행하시겠습니까?",
        // TODO : 필요시 추가하세요.
        type: "info", // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.sopWorkPermitId = '';
          this.data.permitNo = '';
          this.data.processCd = '';
          this.data.sopName = '';
          this.data.vendorCd = '';
          this.data.vendorName = '';
          this.data.tbmWorkDate = '';
          this.data.tbmWorkResponsibleId = '';
          this.data.tbmWorkResponsibleName = '';
          this.data.mapName = '';
          this.data.tbmAttendeeModelList = [];
          this.$_.forEach(this.data.tbmRiskHazardModelList, (item) => {
          if (!this.data.deleteTbmRiskHazardModelList) {
            this.data.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteTbmRiskHazardModelList.push(item);
          }
          this.data.tbmRiskHazardModelList = this.$_.reject(
            this.data.tbmRiskHazardModelList,
            item
          );
        });
          this.data.tbmRiskHazardModelList = [];
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: 'TBM을 삭제하시겠습니까? 삭제할 시 모든 정보가 삭제됩니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addAttendee1() {
      this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        isDayJobAll: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup1;
    },
    // addAttendee1() {
    //   this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
    //   this.popupOptions.param = {
    //     tbmId: this.popupParam.tbmId,
    //   };
    //   this.popupOptions.target = () => import(`${"./tbmDayJobAttendeePop.vue"}`);
    //   this.popupOptions.width = "60%";
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeAttendeePopup1;
    // },
    closeAttendeePopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.tbmAttendeeModelList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              spotName: _item.spotName,
              jobName: '일용직',
              tbmAttendeeJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              healthStatusCd: "THS0000001",
              deptName: _item.deptName,
              mentalFatigueFlag: "N",
              electronSignature: '',
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              eduAttendFlag: 'N',
              physicalDefectFlag: "N",
              tbmDirectFlag: "N",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee2() {
      this.popupOptions.title = "사용자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.data.plantCd,
        isAllVendor: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup2;
    },
    closeAttendeePopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.tbmAttendeeModelList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              spotName: _item.spotName,
              jobName: _item.jobName,
              tbmAttendeeJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              healthStatusCd: "THS0000001",
              deptName: _item.deptName,
              mentalFatigueFlag: "N",
              electronSignature: '',
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              eduAttendFlag: 'N',
              physicalDefectFlag: "N",
              tbmDirectFlag: "N",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.data.deleteTbmAttendeeModelList) {
            this.data.deleteTbmAttendeeModelList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteTbmAttendeeModelList, {
              tbmAttendeeId: item.tbmAttendeeId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteTbmAttendeeModelList.push(item);
          }
          this.data.tbmAttendeeModelList = this.$_.reject(
            this.data.tbmAttendeeModelList,
            item
          );
        });
      }
    },
    callback(data, rowIndex) {
      this.data.tbmAttendeeModelList[rowIndex].electronSignature = data
      this.data.tbmAttendeeModelList[rowIndex].eduAttendFlag = 'Y'
      if(this.data.tbmAttendeeModelList[rowIndex].editFlag !== 'C') {
        this.data.tbmAttendeeModelList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
    // listView() {
    //   this.scenarioViewGroup = 'L'
    // },
    // cardView() {
    //   this.scenarioViewGroup = 'C'
    // },
  },
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>